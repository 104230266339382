.titulo{

font-family: "helvetica";
font-size: 43px;
font-weight: bolder;
color: #000000; 
width:100%;
position:relative;
margin-top: 35vh;
letter-spacing: -3px ;

 }


.titulo1{
  font-family: "helvetica";
  font-size: 27px;
  color:#030303c0;
  font-weight: bold;
  letter-spacing: -1.7px ;
}



.paginaUno{
  font-family: "helvetica";
  font-size: 50px;
  color:hsl(0, 0%, 0%);
  font-weight: bold;
  letter-spacing: -3px ;
  
}

.paginaDos{
  font-family: "helvetica";
  font-size: 30px;
  color:#fafafa;
  font-weight: bold;
}
.paginaTres{
  font-family: "helvetica";
  font-size: 25px;
  color:black#000000c0;
  font-weight: bold;
  letter-spacing: -3px ;
}

.subtituloTres{
  font-family: "helvetica";
  font-size: 25px;
  color:#D61987;
  font-weight: bold;
  letter-spacing: -3px ;
}

.container1{

  font-size: 32px;
 
}

.tituloCel{

  font-family: "helvetica";
  font-size: 26px;
  font-weight: bolder;
  color: #000000; 
  width:100%;
  position:relative;
  margin-top: 35vh;
  letter-spacing: -3px ;

}

  .titulo1Cel{
    font-family: "helvetica";
    font-size: 13px;
    color:#030303c0;
    font-weight: bold;
    letter-spacing: -1.7px ;
  }

  .paginaUnoY{
    font-family: "helvetica";
    font-size: 17px;
    color:#000000c0;
    font-weight: bold;
    letter-spacing: -3px ;
  }

  .paginaDosY{
    font-family: "helvetica";
    font-size: 15px;
    color:#ffffffc0;
    font-weight: bold;
  }

  .paginaUnoD{
    font-family: "helvetica";
    font-size: 23px;
    color:#000000c0;
    font-weight: bold;
    letter-spacing: -3px ;
  }

  .paginaDosP{
    font-family: "helvetica";
    font-size: 29px;
    color:#ffffff;
    font-weight: bold;
  }
  
  .App-credits {
    background-color: #fff176;
    color: #000000;
    line-height: 40px;
    text-align:center;
    
  }
  

  .icon {
    position: relative;
    overflow: hidden;
    
    display: inline-block;
    
    margin: 25px 0 25px 25px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
  
  }
  
  
  /**
   * The "shine" element
   */
  
  .icon:after {
    
    animation: shine 5s ease-in-out  infinite;
    animation-fill-mode: forwards;  
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
  }
  
  /* Hover state - trigger effect */
  
  
  /* Active state */
  
  .icon:active:after {
    opacity: 0;
  }
  
  @keyframes shine{
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  }
  